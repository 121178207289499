import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import config from '../../config/site';
import { Layout, Container, Content } from 'layouts';
import { TagsBlock, Button, SEO, Hero, Title, JobForm } from 'components';
import theme from '../../config/theme';
import i18n from '../i18n/i18n';

const EntryHeader = styled.div`
  position: relative;
  margin-top: -6.5rem;
  padding: 6.5rem;
  background: ${theme.colors.white.base};
  display: flex;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 3rem 2rem 0;
    margin-right: 1.5rem;
    margin-top: -4rem;
    display: block;
  }

  .text {
    flex: 1;
    @media (max-width: ${theme.breakpoints.s}) {
      margin-bottom: 2rem;
    }
  }

  .button {
    margin-top: 2rem;
  }

  .subtitle {
    letter-spacing: ${theme.letterSpacing.wide};
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.small};
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
  }

  h1 {
    font-weight: bold;
    line-height: ${theme.lineHeight.heading};
    margin-bottom: 1rem;

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.xlarge};
      margin-bottom: 0.5rem;
    }
  }

  span {
    font-size: ${theme.fontSize.small};
    text-transform: capitalize;
    + span {
      &:before {
        content: ',';
        margin-right: 0.5rem;
      }
    }
  }
`;

const Entry = styled.div`
  background-color: ${theme.colors.white.light};
  padding: 6.5rem 0;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 4rem 0;
  }
`;

const Jobs = ({
  data,
  pageContext: {
    pathSlug,
    locale,
    defaultSlug,
    supportedLangs,
  }
}) => {

  i18n.changeLanguage(locale)

  /**
   * JOBが存在しない場合は
   * そもそもgatsby-node.jsでページを生成しないので
   * ここでは存在チェックはしない
   */
  const job = data.markdownRemark;
  const title = job.frontmatter.title;
  const html = job.html;

  return (
    <Layout
      headerWhite={true}
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <SEO
        title={`${title} - ${config[locale].title}`}
        desc={job.frontmatter.description || job.excerpt || ' '}
        pathname={pathSlug}
        article
      />
      <Hero isRandomCover={true} />
      <Container type="article">
        <EntryHeader>
          <div className="text">
            <p className="subtitle">{i18n.t("jobPage.subtitle")}</p>
            <h1>{title}</h1>
            <span>{job.frontmatter.titleJp}</span>
            <span>{job.frontmatter.employmentType}</span>
            <span>{job.frontmatter.country}</span>
          </div>
          <div className="button">
            <Button to="#entry" title={i18n.t("jobPage.apply")} size="small" />
          </div>
        </EntryHeader>
      </Container>
      <Container type="article">
        <Content input={html} />
        <TagsBlock list={job.frontmatter.tags || []} />
      </Container>
      <Entry id="entry">
        <Title title={i18n.t("jobPage.apply")} />
        <JobForm job={title} />
      </Entry>
    </Layout>
  );
};

export default Jobs;

Jobs.propTypes = {
  pageContext: PropTypes.shape({
    pathSlug: PropTypes.string,
  }),
  data: PropTypes.object.isRequired,
};

/**
 * pathSlugがロケール別になっているので
 * ロケールでフィルターはしない
 */
export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(fields: { slug: { eq: $pathSlug } }) {
      html
      fields {
        localePath
        slug
        defaultSlug
      }
      frontmatter {
        title
        titleJp
        country
        employmentType
        department
        description
        path
      }
    }
  }
`;
